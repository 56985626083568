import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(5),
      width: "30ch",
      display: "flex",
    },
  },
  input: {
    color: "fff",
  },
}));

const securityQuestions = [
  {
    value: "-- Select Security Question",
    label: "-- Select Security Question",
  },
  {
    value: "What was your childhood nickname?",
    label: "What was your childhood nickname?",
  },
  {
    value: "In what city did you mother and father meet?",
    label: "In what city did your mother and father meet?",
  },
  {
    value: "What is your favourite movie?",
    label: "What is your favourite movie?",
  },
  {
    value: "What is your favourite color?",
    label: "What is your favourite color?",
  },
  {
    value: "What is your mother's maiden name?",
    label: "What is your mother's maiden name?",
  },
  {
    value: "What phone number do your remember most from your childhood?",
    label: "What phone number do your remember most from your childhood?",
  },
];

const ForgotPasswordComponent = () => {
  const classes = useStyles();
  let navigate = useNavigate();

  const [email, setEmail] = useState();
  const [security_question, setSecurityQuestion] = useState("");
  const [security_answer, setSecurtyAnswer] = useState("");
  const [password_hash, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      email: email,
      security_question: security_question,
      security_answer: security_answer,
      password_hash: password_hash,
      confirm_password: confirm_password,
    };
    const url = "https://atoptradefx-server-production.up.railway.app/user/reset/password";
    try {
      let res = await axios.post(url, data);
      if (res.status === 200) {
        navigate("/");
        setLoading(false);
      } else {
        console.log("error updating password");
        setLoading(false);
        setError("error updating password");
      }
    } catch (err) {
      setLoading(false);
      setError("error updating password");
    }
  };

  return (
    <div style={{ alignItems: "center" }}>
      <center>
        <br />
        <h1 style={{ color: "gold" }}>Reset Password</h1>
        <div>
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off">
            <TextField
              id="outlined-basic"
              color="red"
              inputProps={{ style: { color: "#fff" } }}
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ style: { color: "gold" } }}
            />
            <TextField
              id="outlined-basic"
              select
              label="Security Question"
              value={security_question}
              onChange={(e) => setSecurityQuestion(e.target.value)}
              InputLabelProps={{ style: { color: "gold" } }}>
              {securityQuestions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="outlined-basic"
              color="red"
              inputProps={{ style: { color: "#fff" } }}
              label="Security Answer"
              variant="outlined"
              value={security_answer}
              onChange={(e) => setSecurtyAnswer(e.target.value)}
              InputLabelProps={{ style: { color: "gold" } }}
            />
            <TextField
              id="outlined-basic"
              color="red"
              inputProps={{ style: { color: "#fff" } }}
              label="Password"
              variant="outlined"
              value={password_hash}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ style: { color: "gold" } }}
            />
            <TextField
              id="outlined-basic"
              color="red"
              inputProps={{ style: { color: "#fff" } }}
              label="Confirm Password"
              variant="outlined"
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputLabelProps={{ style: { color: "gold" } }}
            />

            {/* <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#eebc1d",
              }}
              type="submit">
              Submit
            </Button> */}
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}>
                <CircularProgress
                  style={{
                    color: "gold",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
              </div>
            ) : (
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#eebc1d",
                }}
                type="submit">
                Submit
              </Button>
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
          </form>
        </div>
      </center>
    </div>
  );
};

export default ForgotPasswordComponent;
