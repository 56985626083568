import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "30ch",
      display: "flex",
    },
  },
}));

const Login = ({ isAuthenticated, isNotAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password_hash, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const classes = useStyles();

  let navigate = useNavigate();

  const handleLogin = async (email, password_hash) => {
    setLoading(true);
    try {
      let res = await axios.post("https://atoptradefx-server-production.up.railway.app/user/signin", {
        email,
        password_hash,
      });
      if (res.status === 200 && res.data.roles === "admin") {
        window.sessionStorage.setItem("firstName", res.data.first_name);
        window.sessionStorage.setItem("lastName", res.data.last_name);
        window.sessionStorage.setItem("email", res.data.email);
        window.sessionStorage.setItem("token", res.data.token);
        window.sessionStorage.setItem("id", res.data.user_id);
        window.sessionStorage.setItem("investment", res.data.investment);
        window.sessionStorage.setItem("roles", res.data.roles);
        isAuthenticated();
        navigate(`/admin/dashboard`);
        setLoading(false);
      } else if (res.status === 200 && res.data.roles === "user") {
        window.sessionStorage.setItem("firstName", res.data.first_name);
        window.sessionStorage.setItem("lastName", res.data.last_name);
        window.sessionStorage.setItem("email", res.data.email);
        window.sessionStorage.setItem("token", res.data.token);
        window.sessionStorage.setItem("id", res.data.user_id);
        window.sessionStorage.setItem("investment", res.data.investment);
        window.sessionStorage.setItem("roles", res.data.roles);
        isAuthenticated();
        setLoginStatus(res.data);
        navigate(`/main/${window.sessionStorage.getItem("id")}`);
        setLoading(false);
      } else {
        setLoading(false);
        setError("Incorrect Login details.");
      }
    } catch (error) {
      setLoading(false);
      setError("Incorrect Login details.");
      // setMessage("incorrect login details");
    }
  };
  return (
    <div>
      <Box
        p={3}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <form
          className={classes.root}
          onSubmit={(event) => {
            event.preventDefault();
            handleLogin(email, password_hash);
          }}>
          <TextField
            variant="outlined"
            type="email"
            label="enter email"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            fullWidth
          />
          <TextField
            variant="outlined"
            type="password"
            label="enter password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
          {/* <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#eebc1d",
            }}
            type="submit">
            Sign In
          </Button>
          <Typography style={{ color: "red" }}>{loginStatus}</Typography> */}

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}>
              <CircularProgress
                style={{
                  color: "gold",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
            </div>
          ) : (
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#eebc1d",
              }}
              type="submit">
              Sign In
            </Button>
          )}
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Link to="/reset-password">Forgot password?</Link>
        </form>
      </Box>
    </div>
  );
};

export default Login;
