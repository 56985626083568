import React from "react";
import ForgotPasswordComponent from "../components/Authentication/ForgotPasswordComponent";

const ForgetPassword = () => {
  return (
    <div>
      <ForgotPasswordComponent />
    </div>
  );
};

export default ForgetPassword;
