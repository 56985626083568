import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    // minWidth: 500,
  },
});

const Users = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = "https://atoptradefx-server-production.up.railway.app/user/register";
        const headers = {
          "Content-Type": "application/json",
          Authorization: window.sessionStorage.getItem("token"),
        };
        const response = await axios.get(url, { headers });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchData();
  }, [deleted]);

  // useEffect(() => {
  //   fetchData();
  // }, [deleted]);

  const deleteUser = async (userId) => {
    try {
      const url = `https://atoptradefx-server-production.up.railway.app/user/register/${userId}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: window.sessionStorage.getItem("token"),
      };
      await axios.delete(url, { headers });
      setDeleted(!deleted); // Trigger re-fetching of data after successful deletion
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const columns = [
    { id: "firstName", label: "First Name", minWidth: 30, align: "center" },
    { id: "lastName", label: "Last Name", minWidth: 30, align: "center" },
    { id: "email", label: "Email", minWidth: 30, align: "center" },
    { id: "Profit", label: "Profit", minWidth: 15, align: "center" },
    { id: "Capital", label: "Capital", minWidth: 15, align: "center" },
    {
      id: "Amount",
      label: "Amount",
      minWidth: 15,
      align: "center",
    },
    {
      id: "Total Number of Trades",
      label: "Total Number of Trades",
      minWidth: 7,
      align: "center",
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
      minWidth: 30,
      align: "center",
    },
    {
      id: "Update Trade",
      label: "Update Trade",
      minWidth: 30,
      align: "center",
    },
    {
      id: "Delete",
      label: "Delete User",
      minWidth: 30,
      align: "center",
    },
  ];

  return (
    <TableContainer component={Paper}>
      {sessionStorage.getItem("roles") === "admin" ? (
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, color: "gold" }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, i) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell align="center">
                    {user.first_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {user.last_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {user.email}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    ${user.profit}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    ${user.capital}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    ${user.amount}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {user.total_number_of_trade}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {user.phone_number}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`/create/trade/${user.user_id}`}>
                      Update Trade
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => deleteUser(user.user_id)}
                    >
                      Delete User
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <p></p>
      )}
    </TableContainer>
  );
};

export default Users;
