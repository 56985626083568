import React from "react";
import BankDetailsComponent from "../components/BankDetails/BankDetailsComponent";

const BankDetails = () => {
  return (
    <div>
      <BankDetailsComponent />
    </div>
  );
};

export default BankDetails;
