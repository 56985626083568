import React, { useState } from "react";
import { Box, Button, TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "30ch",
      display: "flex",
    },
  },
}));
const investments = [
  {
    value: "Basic",
    label: "Basic",
  },
  {
    value: "Standard",
    label: "Standard",
  },
  {
    value: "Classic",
    label: "Classic",
  },
  {
    value: "Ultimate",
    label: "Ultimate",
  },
];
const userRoles = [
  {
    value: "user",
    label: "user",
  },
];

const securityQuestions = [
  {
    value: "-- Select Security Question",
    label: "-- Select Security Question",
  },
  {
    value: "What was your childhood nickname?",
    label: "What was your childhood nickname?",
  },
  {
    value: "In what city did you mother and father meet?",
    label: "In what city did your mother and father meet?",
  },
  {
    value: "What is your favourite movie?",
    label: "What is your favourite movie?",
  },
  {
    value: "What is your favourite color?",
    label: "What is your favourite color?",
  },
  {
    value: "What is your mother's maiden name?",
    label: "What is your mother's maiden name?",
  },
  {
    value: "What phone number do your remember most from your childhood?",
    label: "What phone number do your remember most from your childhood?",
  },
];

const Signup = ({ handleClose, setValue }) => {
  const [email, setEmail] = useState("");
  const [password_hash, setPassword] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [investment, setInvestment] = useState("Basic");
  const [invitation_code, setInvitationCode] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [date_of_birth, setDate_of_birth] = useState("");
  const [address, setAddress] = useState("");
  const [security_question, setSecurityQuestion] = useState("");
  const [security_answer, setSecurityAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const register = async (
    first_name,
    last_name,
    email,
    password_hash,
    phone_number,
    date_of_birth,
    address,
    investment,
    invitation_code,
    security_question,
    security_answer
  ) => {
    setLoading(true);
    try {
      let res = await axios.post("https://atoptradefx-server-production.up.railway.app/user/register", {
        first_name,
        last_name,
        email,
        password_hash,
        phone_number,
        date_of_birth,
        address,
        investment,
        invitation_code,
        security_question,
        security_answer,
      });
      if (res.status === 200) {
        setValue(0);
        window.sessionStorage.setItem("firstName", res.data.first_name);
        window.sessionStorage.setItem("lastName", res.data.last_name);
        window.sessionStorage.setItem("email", res.data.email);
        window.sessionStorage.setItem("id", res.data.user_id);
        window.sessionStorage.setItem("investment", res.data.investment);
        window.sessionStorage.setItem("roles", res.data.roles);
        window.sessionStorage.setItem("token", res.data.token);
        window.setSecurityQuestion.setItem(
          "invitation_code",
          res.data.invitation_code
        );
        window.sessionStorage.setItem(
          "securityQuestion",
          res.data.security_question
        );
        setLoading(false);
      }
    } catch (err) {
      setError("incorrect registration details");
      setLoading(false);
    }
  };
  return (
    <Box
      p={3}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "400px",
        overflow: "scroll",
      }}
    >
      <form
        className={classes.root}
        onSubmit={(event) => {
          event.preventDefault();
          register(
            first_name,
            last_name,
            email,
            password_hash,
            phone_number,
            date_of_birth,
            address,
            investment,
            invitation_code,
            security_question,
            security_answer
          );
        }}
      >
        <TextField
          variant="outlined"
          type="name"
          label="First name"
          value={first_name}
          onChange={(e) => setFirst_name(e.target.value)}
          fullWidth
        />
        <TextField
          variant="outlined"
          type="name"
          label="Last name"
          value={last_name}
          onChange={(e) => setLast_name(e.target.value)}
          fullWidth
        />
        <TextField
          id="standard-select-currency"
          select
          label="Security Question"
          value={security_question}
          onChange={(e) => setSecurityQuestion(e.target.value)}
        >
          {securityQuestions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="outlined"
          type="test"
          label="Security Answer"
          value={security_answer}
          onChange={(e) => setSecurityAnswer(e.target.value.toLowerCase())}
          fullWidth
        />
        <TextField
          variant="outlined"
          type="email"
          label="enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          fullWidth
        />
        <TextField
          variant="outlined"
          type="password"
          label="enter password"
          value={password_hash}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
        <TextField
          variant="outlined"
          type="number"
          label="Phone Number"
          value={phone_number}
          onChange={(e) => setPhone_number(e.target.value)}
          fullWidth
        />
        <TextField
          variant="outlined"
          type="date"
          label="Date of birth"
          value={date_of_birth}
          onChange={(e) => setDate_of_birth(e.target.value)}
          fullWidth
        />
        <TextField
          variant="outlined"
          type="text"
          label="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
        />
        <TextField
          variant="outlined"
          type="number"
          label="Invitation Code"
          value={invitation_code}
          onChange={(e) => setInvitationCode(e.target.value)}
          fullWidth
        />
        <TextField
          id="standard-select-currency"
          select
          label="Investment"
          value={investment}
          onChange={(e) => setInvestment(e.target.value)}
          helperText="Please select your investment plan"
        >
          {investments.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <CircularProgress
              style={{
                color: "gold",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            />
          </div>
        ) : (
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#eebc1d",
            }}
            type="submit"
          >
            Sign Up
          </Button>
        )}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </form>
    </Box>
  );
};

export default Signup;
