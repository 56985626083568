import React from "react";
import UserTradeComponent from "../components/UserTrade/UserTrade.component";
const UserTrade = () => {
  return (
    <div>
      <UserTradeComponent />
    </div>
  );
};

export default UserTrade;
