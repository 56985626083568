import React from "react";
import UpdateTradeDetails from "../components/UpdateTrade/UpdateTradeDetails";

const UpdateTrade = () => {
  return (
    <div>
      <UpdateTradeDetails />
    </div>
  );
};

export default UpdateTrade;
